import { Button } from '@finn/design-system/atoms/button';
import { Input, InputProps } from '@finn/design-system/atoms/input';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { connect, FormikProps } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { useIntl } from 'react-intl';

import { B2BContactFormContent } from '~/types/cosmicModules';
import { getInputPlaceholder } from '~/utils/cosmic';

import { LegacySelectField } from '../FormElements/SelectField';
import TextArea from '../FormElements/TextArea';
import { ContactValues } from './config';

const options = [
  { value: 'Herr', label: 'Herr' },
  { value: 'Frau', label: 'Frau' },
];

// TODO we should kill this as soon as formik is dead
export const FormikInput = connect<InputProps>(
  ({ formik, inputRef, containerRef, name, onBlur, onChange, ...props }) => {
    const i18n = useIntl();
    // for legacy reasons get from lodash is used
    const wasSubmitted = formik?.submitCount > 0;
    const value = get(formik?.values, name, '');
    const touched = get(formik?.touched, name, false);
    const error = (touched || wasSubmitted) && get(formik?.errors, name);
    const errorMessage = error && i18n.formatMessage({ id: error });
    const track = useTrackingStore((state) => state.track);

    return (
      <Input
        name={name}
        containerRef={containerRef}
        ref={inputRef}
        error={errorMessage}
        value={value}
        onChange={(e) => {
          formik?.setFieldValue?.(name, e?.target?.value || e?.phone);
          track(TrackingEventName.FORM_FIELD_ENTERED, {
            fieldName: name,
            once: true,
          });
          onChange?.(e);
        }}
        onBlur={(e) => {
          formik?.handleBlur?.(e);
          onBlur?.(e);
        }}
        {...props}
      />
    );
  }
);

interface IProps {
  formik: FormikProps<ContactValues>;
  loading: boolean;
  cosmicContent: B2BContactFormContent;
}
const Form: React.FunctionComponent<IProps> = ({
  formik,
  loading,
  cosmicContent,
}) => {
  const i18n = useIntl();
  const {
    metadata: {
      input_fields: { phone_number_format },
      submit_button: { button_label },
    },
  } = cosmicContent;

  return (
    <form>
      <div className="grid-cols-2 gap-4">
        <div className="col-span-2 sm:col-span-1">
          <LegacySelectField
            name="salutation"
            placeholder={getInputPlaceholder(cosmicContent, 'salutation')}
            options={options}
            className="bg-white"
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormikInput
            name="company"
            label={getInputPlaceholder(cosmicContent, 'company')}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormikInput
            name="firstName"
            label={i18n.formatMessage({ id: 'b2b.firstName' })}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormikInput
            name="lastName"
            label={getInputPlaceholder(cosmicContent, 'last_name')}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormikInput
            name="email"
            type="email"
            label={getInputPlaceholder(cosmicContent, 'email_address')}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormikInput
            type="tel"
            name="phoneNumber"
            label={getInputPlaceholder(cosmicContent, 'phone_number')}
            placeholder={phone_number_format}
          />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <FormikInput
            name="fleetSize"
            label={getInputPlaceholder(cosmicContent, 'fleet_size')}
            type="number"
          />
        </div>
        <div className="col-span-2">
          <TextArea
            name="message"
            placeholder={getInputPlaceholder(cosmicContent, 'message')}
          />
        </div>
        <div className="col-span-2">
          <div className="sm:flex sm:justify-end">
            <Button
              onClick={() => {
                formik.handleSubmit();
              }}
              className="w-full"
              loading={loading}
            >
              {button_label}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};
export default Form;
